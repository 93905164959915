<script>
import {mapState} from "vuex";

/**
 * User Profile Sidebar Form component
 */
export default {
    data() {
        return {
            toDoList: this.user ? this.user.userToDo : [],
            currUrl: this.$route.name
        }
    },
    computed:{
      ...mapState({
        user: state => state.auth.currentUser
      })
    },

    methods: {

        navigateTo(location){
            this.$router.push({name: location});
        }

    }
}
</script>
<style lang="scss">
.profile-navigation{
    padding:10px 15px;
    font-size:1.1em;
    color:#242424;
    cursor:pointer;
    border-radius:12px;
    i{
        padding-right:10px;
        padding-top:2px;
        font-size:1.1em;
    }
    &:hover{
      color:#fff;
        background-color:$color_primary;

    }
    &.active{
      color:#fff;
        background-color:$color_primary;
    }
}
</style>
<template>
<div class="col-lg-3 col-xl-3">
    <ul style="padding-left:0;list-style-type:none;">
        <li>
            <div class="profile-navigation" :class=" currUrl === 'Profile' ? 'active' : ''" @click="navigateTo('Profile')"><i class="icon-user"></i> Προφιλ χρήστη</div>
        </li>
        <li v-if="!isEducational">
            <div class="profile-navigation" :class=" currUrl === 'company' ? 'active' : ''" @click="navigateTo('company')"><i class="icon-globe"></i> Στοιχεία εταιρείας</div>
        </li>
        <li>
            <div class="profile-navigation" :class=" currUrl === 'change-password' ? 'active' : ''" @click="navigateTo('change-password')"><i class="icon-lock"></i> Κωδικός πρόσβασης</div>
        </li>
        <li>
            <div class="profile-navigation" :class=" currUrl === 'user-todo' ? 'active' : ''" @click="navigateTo('user-todo')"><i class="icon-check"></i> Λίστα to-do</div>
        </li>
        <li>
            <div class="profile-navigation" :class=" currUrl === 'user-tags' ? 'active' : ''" @click="navigateTo('user-tags')"><i class="icon-tag"></i> Ετικέτες</div>
        </li>
        <li v-if="!isEducational">
            <div class="profile-navigation" :class=" currUrl === 'user-subscription' ? 'active' : ''" @click="navigateTo('user-subscription')"><i class="icon-refresh"></i> Πληροφορίες συνδρομής & ανανέωση</div>
        </li>
        <li>
            <div class="profile-navigation" :class=" currUrl === 'print-templates' ? 'active' : ''" @click="navigateTo('print-templates')"><i class="icon-printer"></i> Πρότυπα εκτύπωσης</div>
        </li>
      <li>
        <div class="profile-navigation" :class=" currUrl === 'questionnaires' ? 'active' : ''" @click="navigateTo('questionnaires')"><i class="icon-question"></i>
          Ερωτηματολόγια</div>
      </li>
    </ul>
</div>
</template>
