<template>
  <div id="questionnaire-list">
    <div class="row">
      <ProfileSidebar></ProfileSidebar>
      <div v-if="questionnaires.length >= 0" class="col-lg-9 col-xl-9">
        <b-card>
          <b-button v-if="questionnaires.length < 5" variant="primary" class="float-right" @click="createQuestionnaire">Προσθήκη νέου</b-button>
          <b-card-title>Ερωτηματολόγια</b-card-title>
          <b-card-sub-title>Δείτε & επεξεργαστείτε τα ερωτηματολόγιά σας</b-card-sub-title>
          <b-alert v-if="questionnaires.length === 5" variant="warning" class="mt-3 mb-3" show>Μπορείτε να έχετε έως 5 ερωτηματολόγια</b-alert>
          <div v-if="questionnaires.length > 0" class="col-md-12 mt-3">
            <b-card  v-for="template in questionnaires" :key="template.id" class="shadow-sm">
              <b-card-text>

                <div class="row">
                  <div class="col-md-6">
                    <span class="small-size">Όνομα προτύπου:</span> {{template.name}}
                  </div>
                  <div class="col-md-2">
                    <span class="small-size">Ενέργειες</span>
                    <b-button variant="warning" size="xs" @click="$router.push('/user/questionnaires/'+template.id)" > Επεξεργασία</b-button>
                    <b-button variant="danger" size="xs" class="ml-1" @click="deleteQuestionnaire(template)"><i class="icon-trash"></i></b-button>
                  </div>

                </div>

              </b-card-text>
            </b-card>
          </div>

        </b-card>


      </div>
    </div>
  </div>
</template>

<script>
import ProfileSidebar from "../ProfileSidebar";

export default{

  components: {ProfileSidebar},
  page: {
    title: 'Λογαριασμός'
  },

  data() {
    return {
      user: this.$store ? this.$store.state.auth.currentUser : {} || {},
      questionnaires: this.$store ? (this.$store.state.auth.printquestionnaires || []) : [] || []
    }
  },
  async created() {
    if(this.questionnaires.length === 0){
      await this.$store.dispatch('auth/getQuestionnaires');
      this.questionnaires = this.$store.state.auth.questionnaires;
    }
  },

  methods:{
    createQuestionnaire(){
      this.$store.dispatch('auth/createQuestionnaire').then((res)=>{
        this.$router.push({name: 'edit-questionnaire', params: {questionnaireId: res.data.id}});
      }).catch(e => {
        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η δημιουργία του ερωτηματολογίου απέτυχε.'});
      });
    },

    deleteQuestionnaire(template){
      let message = {
        title: 'Θέλετε σίγουρα να διαγράψετε το ερωτηματολόγιο;',
        body: 'Προσοχή. Δεν υπάρχει δυνατότητα επαναφοράς άπαξ και ολοκληρωθεί η διαγραφή.'
      };
      this.$dialog.confirm(message, {
        loader: true,
        okText: 'Διαγραφή',
        cancelText: 'Ακύρωση',
      }).then((dialog) => {

        this.$store.dispatch('auth/deleteQuestionnaire', template ).then((res)=>{
          dialog.close();
        }).catch(() => {
          this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η διαγραφή απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});
          dialog.close();
        });

      }).catch(()=>{});
    },
  }
}
</script>
